<template>
  <div class="section-wrapper">
    <b-breadcrumb>
      <b-breadcrumb-item to="/dashboard">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/book-section">Manage Book</b-breadcrumb-item>
      <b-breadcrumb-item to="/tax-types">Tax Types</b-breadcrumb-item>
      <b-breadcrumb-item :to="`/tax-subtypes/${cn(taxBook, 'tax_type_id')}`">Subtypes</b-breadcrumb-item>
      <b-breadcrumb-item :to="`/tax-books/${cn(taxBook, 'tax_subtype_id')}`">Tax Books</b-breadcrumb-item>
      <b-breadcrumb-item active>Menus</b-breadcrumb-item>
    </b-breadcrumb>
      <b-card class="mt-5">
          <b-card-text class="p-5">
              <b-row class="p-4">
                <b-col sm="12 text-center" class="">
                  <h1 v-if="taxBook" v-html="taxBook.name" class="mb-3 text-info"></h1>
                  <h4>Tax Book Menus</h4>
                </b-col>
              </b-row>
              <b-row class="mb-5">
                <b-col sm="12 text-center" class="">
                  <router-link class="btn btn-outline-info btn-lg p-5" :to="`/tax-book-sections/${$route.params.tax_book_id}`">Book Sections</router-link>
                  <router-link class="btn btn-outline-info btn-lg p-5 ml-5" :to="`/tax-book-pages/${$route.params.tax_book_id}`">Book Pages</router-link>
                </b-col>
              </b-row>
          </b-card-text>
      </b-card>
  </div>
</template>

<script>
import RestApi, { apiBaseURL } from '@/config'
export default {
  data () {
    return {
      // pagination
      rows: 100,
      currentPage: 1,
      // form data
      search: {
        name: '',
        // access_type: 0,
        tax_book_id: ''
      },
      taxBook: '',
      listData: [],
      loading: false,
      editItem: ''
    }
  },
  created () {
    this.search.tax_book_id = this.$route.params.tax_book_id
    this.loadData()
  },
  methods: {
    gotoChildSectionPage (id) {
      this.$router.push(`/section-child/${id}`)
    },
    async loadData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      var result = await RestApi.getData(apiBaseURL, 'admin/ajax/get_tax_book_by_id', params)
      if (result.success) {
        this.taxBook = result.taxBook
      }
      this.loading = false
    }
  }
}
</script>
<style>

</style>
